import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import test from '../../images/default.png';
import squiggly from '../../images/theme/productCurvyLines.png';
import spy from '../../images/spy.svg';
import secure from '../../images/secure.svg';
import gorilla from '../../images/network.svg';


const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#d2eeb7',//theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    },
    marginFix: {
        marginTop: '10px'
    },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
          <Container className={classes.container}
              style={{marginTop: '1%'}}
          >
        <img
          src={squiggly}
          //src="/static/themes/onepirate/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
              />
              <span style={{ textAlign: 'center' }}><Typography
                  style={{ textShadow: '1px 1px 1px #2fd1f3',marginBottom:'60px' }}
                  variant="h4">GORILLA CHAT IS CURRENTLY IN <span style={{color:'red'}}>EARLY BETA</span> <p>SEE OUR INFO PAGE MORE DETAILS!</p></Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={secure}
                //alt="suitcase"
              />
              <Typography variant="h6"  style={{marginTop: '5px',marginBottom: '20px'}} className={classes.title}>
                Powerful Text Chat
              </Typography>
              <Typography variant="h5">
                {'A P2P text chat experience with low latency and full anonymity. '}
                {''}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={gorilla}
                //alt="graph"
              />
              <Typography variant="h6" style={{marginTop: '5px',marginBottom: '20px'}} className={classes.title}>
                Completely Decentralized
              </Typography>
                              <Typography variant="h5">
                                  {'Nobody can control or censor Gorilla Chat, and your chatroom will '} <b style={{ fontSize: '20px', fontWeight: '550' }}>never go offline. </b>
                {''}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={spy}
                //alt="clock"
                              />
                <Typography variant="h6" className={classes.title} style={{marginTop: '5px',marginBottom: '20px'}}>
                Built for Privacy
              </Typography>
              <Typography variant="h5">
                {'No IP leaks. Full data ownership. '}
                {'Why trust a company when you can trust yourself?'}
              </Typography>
            </div>
          </Grid>
                  </Grid>
              </span>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
