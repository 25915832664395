import React from 'react'
import style from './index.module.css'
import users from './../../../images/users.svg';

export const RoomHeader = ({
  state: { room, user, sidebarOpen, userListOpen },
  actions: { setSidebar, setUserList },
}) => (
        <header className={style.component}
            style={{ backgroundColor:' #CFF2F9' }}

        >
    <button onClick={e => setSidebar(!sidebarOpen)}>
      <svg>
        <use xlinkHref="index.svg#menu" />
      </svg>
    </button>
            <h1>{/*&& room.name.replace(user.id, '')*/}<i>{"#"+room.name}</i></h1>
    {room.users && (
                <div onClick={e => setUserList(!userListOpen)} style={{ backgroundColor:'#c7effa'}}>
        <span>{room.users.length}</span>
                    <img src={users} style={{ maxWidth: '30px', height: 'auto', padding: '0px', marginRight: '2px' }}>
          
        </img>
      </div>
    )}
  </header>
)
