import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Markdown from './modules/components/Markdown';
import Typography from './modules/components/Typography';
import AppAppBar from './modules/views/AppAppBar';
import privacy from './modules/views/privacy.md';
import AppFooter from './modules/views/AppFooter';

function Privacy() {
  return (
    <React.Fragment>
      <Container>
        <Box mt={7} mb={12}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Privacy
          </Typography>



          <p>Thank you for choosing to be part of our community at Gorilla Chat (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy.</p>
          <p>When you visit our site and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our and our services. </p>
          <p>This privacy policy applies to all information collected through our and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the &quot;Services&quot;).  </p>
          <p>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.   </p>
          <h2 id="1-what-information-do-we-collect-">1. WHAT INFORMATION DO WE COLLECT?</h2>
          <p>Personal information you disclose to us </p>
          <p>In Short: We collect personal information that you provide to us such as name, address, contact information, passwords and security data, and payment information.</p>
          <p>We collect personal information that you voluntarily provide to us when registering expressing an interest in obtaining information about us or our products and services, when participating in activities on the site (such as posting messages in our online forums or entering competitions, contests or giveaways) or otherwise contacting us.</p>
          <p>Information automatically collected </p>
          <p>In Short: Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our site.</p>
          <p>We automatically collect certain information when you visit, use or navigate the site. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our site, and for our internal analytics and reporting purposes.</p>
          <h2 id="2-will-your-information-be-shared-with-anyone-">2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
          <p>In Short:  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.   </p>
          <p>We may process or share data based on the following legal basis:
Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose. </p>
          <p>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests. </p>
          <p>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract. </p>
          <p>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</p>
          <p>Other Users. When you share personal information or otherwise interact with public areas of the app, such personal information may be viewed by all users and may be publicly distributed outside the in perpetuity. Other users will be able to view descriptions of your activity, communicate with you within our app, and view your profile.    </p>
          <h2 id="3-is-your-information-transferred-internationally-">3. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
          <p>In Short:  We may transfer, store, and process your information in countries other than your own.  </p>
          <p>Our servers are located in the United States. If you are accessing our from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities. </p>
          <p>If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law.                     </p>
          <h2 id="4-do-we-collect-information-from-minors-">4. DO WE COLLECT INFORMATION FROM MINORS?</h2>
          <p>In Short:  We do not knowingly collect data from or market to children under 18 years of age.  </p>
          <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the site, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the . If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.</p>
          <h2 id="5-what-are-your-privacy-rights-">5. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
          <p>In Short:  You may review, change, or terminate your account at any time.</p>
          <p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>. </p>
          <p>Account Information  </p>
          <p>If you would at any time like to review or change the information in your account or terminate your account, you can: </p>
          <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
          <h2 id="6-controls-for-do-not-track-features">6. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
          <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.  </p>
          <h2 id="7-do-california-residents-have-specific-privacy-rights-">7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
          <p>In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.   </p>
          <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below. </p>
          <p>If you are under 18 years of age, reside in California, and have a registered account with the site, you have the right to request removal of unwanted data that you publicly post on the . To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the site, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>





        </Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Privacy);
