import React from 'react'
import style from './index.module.css'

export const WelcomeScreen = () => (
  <section>
    <div className={style.component}>
      <span role="img" aria-label="post">
        <svg viewBox="0 0 59 80">
          <g>
		  
          </g>
        </svg>
      </span>
      <p>
        Woops! You do not have a valid blockstack id!
        <br />
        Maybe try to log in again?
      </p>
    </div>
  </section>
)
