import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import Button from '@material-ui/core/Button';
import logo from '../../images/isolated-transparent-green.svg';

const styles = theme => ({
  title: {
    fontSize: 24,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
    height: '5vh',
    //width: '1px'
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
});

const appBarFix = {
  height: '5vh',
  maxHeight: '5vh',
  minHeight: '30px',
  paddingLeft: '0px'
  //width: '1px'
};

function AppAppBar(props) {
  const { classes, session, appStatus, signin, signout } = props;
  console.log(props);

  return (
    <div style={appBarFix}>
      <AppBar position="fixed" style={appBarFix}>
        <Toolbar style={appBarFix} className={classes.toolbar} >
            <img src={logo} style={{ maxHeight: '85%', cursor:'pointer' }} onClick={() => {window.location.pathname = '/'}}></img>

          <Link
            variant="h6"
            underline="none"
            color="inherit"
            className={classes.title}
            href="/premium-themes/onepirate/"
          >
            {''}
                  </Link>
                    
                  <div className={classes.right}>
                      <Button
                          variant="h6"
                          underline="none"
                          className={clsx(classes.rightLink, classes.linkSecondary)}
                          href="/help"
                      //onClick={href="/"}
                      >
                          {'INSTRUCTIONS / INFO'}
                      </Button>
            {!appStatus && session.isUserSignedIn() &&
              <Button
                color="inherit"
                variant="h6"
                underline="none"
                className={classes.rightLink}
                href="/app"
              >
                {'Launch App'}
              </Button>
                      }

            {!session.isUserSignedIn() ? <Button
              color="inherit"
              variant="h6"
              underline="none"
              className={classes.rightLink}
              //href="/sign-in/"
              onClick={signin}
            >
              {'Sign In With Blockstack'}
            </Button>
              :
              <Button
                variant="h6"
                underline="none"
                className={clsx(classes.rightLink, classes.linkSecondary)}
                href="/"
                onClick={signout}
              >
                {'Sign Out'}
              </Button>
            }
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
