import React from 'react'
import style from './index.module.css'
import { dots } from '../TypingIndicator/index.module.css'

const Icon = id => (
  <svg>
    <use xlinkHref={`index.svg#${id}`} />
  </svg>
)

const unreads = (user, room, messages = {}) => {
  //const read = user.readCursor({ roomId: room.id }) //read information TODO
  //return (
  //  (read && Object.keys(messages).filter(x => x > read.position).length) ||
  //  undefined
  //)
    return 0;
}

const priority = (user, room, messages = {}) => {
  const unreadMessages = unreads(user, room, messages) || 0
  const lastMessage = Object.keys(messages).pop() || 0
  return (10 * unreadMessages + parseInt(lastMessage)) * -1
}

export const RoomList = ({
    rooms = [],
    user,
    messages,
    current,
    typing,
    actions,
}) => (
        <ul className={style.component} style={{ backgroundColor: '#147643', borderWidth:'0px' }}>
    {rooms.map(room => {
      const messageKeys = Object.keys(messages[room.id] || {})
      const latestMessage =
          messageKeys.length > 0 && messages[room.id][messageKeys.pop()]
                //console.log(latestMessage.sender)
                //console.log(latestMessage.sender.id)
      const firstUser = room.users.find(x => x.id !== user.id)
      const order = priority(user, room, messages[room.id])
      const unreadCount = unreads(user, room, messages[room.id])
                return (
                    <div>
                    <div

                        style={{
                            pointer: 'none',
                            position: 'absolute', bottom: '57px', display: 'flex', flexDirection: 'column',
                            paddingLeft: '6px', fontSize: '14px', zIndex: '6', borderBottom: '0px solid rgba(0, 0, 0, 0.38)', //borderRight: '1px solid rgba(0, 0, 0, 0.38)',
                            paddingRight: '5px', fontFamily: 'Candara', //fontWeight: '500', //transform: 'scaleX(1.5)',
                                backgroundColor: '#7e5135',//'rgba(244, 244, 244, 1)',
                            borderRadius: '10px 10px 0px 0px', color:'white ',//color: 'rgba(0, 0, 0, 0.62)',
                                paddingTop: '2px', boxShadow: '0px 0px 0px 1px black', left: '0px', textAlign: 'center', display: 'inline-block',
                                width: '100%', borderRight: '1px solid #362317', borderTop: '1px solid #362317'
                            }}><h1 style={{ textShadow: '0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black'}}><i>Join Server</i></h1></div>
        <li
          key={room.id}
          disabled={room.id === current.id}
          onClick={e => actions.joinRoom(room)}
          style={{ order, color:'#f2f1f3' }}
        >
              {/*{room.name.match(user.id) && firstUser ? (
            <img src={firstUser.avatarURL} alt={firstUser.id} />
          ) : (
            Icon(room.isPrivate ? 'lock' : 'public')
          )}*/}

          <col->
            <p>{"# " + room.name/*.replace(user.id, '')*/}</p>
                                {latestMessage.sender &&
                                    <span style={{ color: '#2ecc69', display:'contents' }}>{latestMessage.sender.id}: 
                                    </span>
                                }
                                {latestMessage.sender &&
                                    <span style={{ color: '#e5e4e7', display: 'contents' }}>{' ' + latestMessage.text}
                                    </span>
                                }
          </col->
          {room.id !== current.id && unreadCount ? (
            <label>{unreadCount}</label>
          ) : Object.keys(typing[room.id] || {}).length > 0 ? (
            <div className={dots}>
              {[0, 1, 2].map(x => (
                <div key={x} />
              ))}
            </div>
                  ) : null}

                        </li>
                        </div>
      )
    })}
            
        </ul>
        
)

/*
 
  <div

                style={{
                position: 'absolute', bottom: '58px', display: 'flex', flexDirection: 'column',
                    paddingLeft: '6px', fontSize: '16px', zIndex: '6', borderBottom: '1px solid rgba(0, 0, 0, 0.38)', //borderRight: '1px solid rgba(0, 0, 0, 0.38)',
                    paddingRight: '5px', fontFamily: 'Arial', fontWeight: '550', //transform: 'scaleX(1.5)',
                    backgroundColor: 'white',//'rgba(244, 244, 244, 1)',
                    borderRadius: '0px 0px 10px 10px', color: 'rgba(0, 0, 0, 0.62)',
                    paddingTop: '2px', boxShadow: '0px 0px 0px 0px', left: -'2px', textAlign: 'center', display: 'inline-block',
                    width: '100%'
            }}>Join Server</div>
 
 */