import React from 'react'
import style from './index.module.css'

export const UserList = ({ room, current, createConvo, removeUser }) => (
    <ul className={style.component} style={{
        backgroundColor: '#147643', borderWidth: '0px', zIndex: '7', 
    }}>
    {room.users ? room.users.map(user => (
      <li
        key={user.id}
        className={user.presence.state === 'online' ? style.online : null}
        onClick={e => createConvo({ user })}
        style={{ order: user.presence.state === 'online' && -1 }} //todo: order based on online or offline, indicators using keepalive. alt={user.name} on img below
      >
        
                <p style={{
                    //color: '#2ecc69',
                    color: '#FFFFFF',
                    textShadow: '0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black'
                    //textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
             
                }}>{user.name}</p>
      </li>
    )) : null}
    {room.users ?
            room.users.length < 100 ? (
                <span>
                                      <br />
                    
                    <li className={style.hint} >
                                <div style={{ color: '#e5e4e7'}}>
                        Type <span style={{ backgroundColor:'#136c3e'}}>/leave</span> in the chat window to leave a server
                    </div>
                                </li>
                    <br />
                    
        <li className={style.hint} >
                    <div style={{ color: '#e5e4e7'}}>
            Type <span style={{ backgroundColor:'#136c3e'}}>/invite <i >username.id.blockstack</i></span> in the chat window to invite a new user
        </div>
                    </li>
                    <br />
        <li className={style.hint} >
        <div style={{ color: '#e5e4e7' }}>
            Type <span style={{ backgroundColor: '#136c3e' }}>/clear</span> in the chat window to clear your entire chatlog
        </div>
        </li>
                    <br />
                    <li className={style.hint} >
                        <div style={{ color: '#e5e4e7' }}>
                            Type <span style={{ backgroundColor: '#136c3e' }}>/remove <i>username.id.blockstack</i></span> in the chat window to remove a user
                        </div>
                    </li>
                    </span>
      ) : null
    : null}
  </ul>
)

//<img src={user.avatarURL} />
