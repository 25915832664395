import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './onepirate/Home.js';

function App() {
  return (
    <>
        <Home />
    </>
  );
}

export default App;
