import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import { UserHeader } from './components/UserHeader'
import { UserList } from './components/UserList'
import { MessageList } from './components/MessageList'
import { TypingIndicator } from './components/TypingIndicator'
import { CreateMessageForm } from './components/CreateMessageForm'
import { RoomList } from './components/RoomList'
import { RoomHeader } from './components/RoomHeader'
import { CreateRoomForm } from './components/CreateRoomForm'
import { WelcomeScreen } from './components/WelcomeScreen'
import { JoinRoomScreen } from './components/JoinRoomScreen'
import * as blockstack from 'blockstack'
import Tree from 'splaytree';
import { lookupProfile } from 'blockstack'
import { withAlert } from "react-alert";
import * as libsodium from 'libsodium';

//var request = require("request");

// --------------------------------------
// Application
// --------------------------------------
class View extends React.Component {
    state = {
        user: {}, //todo: user.rooms as a data format, figure out how to store it and recieve through blockstack instead.
        room: {},
        messages: {}, //messages for the room(?), should make custom function to retrive from gaia.
        typing: {},
        sidebarOpen: false,
        userListOpen: window.innerWidth > 1000,
        pendingMessages: [],
    }

    constructor(props) {
        super(props);
        this.massive = [];
        this.localWhitelist = {};
        this.counter = 0;
        this.delStopPolling = false;

        //this.pendingMessages = new Tree();
        this.sentNewMessage = false;

        this.initData();

        //https://stackoverflow.com/questions/8732191/setinterval-only-running-function-once
        //var tempThis = this;
        //window.setInterval(function () {
        //    tempThis.GetAndSetMessages(tempThis.state.room.id)
        //    //console.log("sup")
        //    //fetch("gorilla.chat", { method: 'HEAD' }).then(headers => {
        //    //    //console.log(headers.headers.get('content-length')); // https://stackoverflow.com/questions/50779681/headers-not-showing-in-fetch-response
        //    //    //resolve(headers); 
        //    //    //WOW! FETCHES USE ONLY ~17% CPU ON BROWSER WHEN 100 PER SECOND! WOW! granted im spamming HEAD but still
        //    //});
        //}, 1000); //rapid polling lol
        //window.setInterval(function () {
        //    tempThis.AssignRoomUsers(tempThis.state.room.id)
        //    //console.log("sup")
        //}, 4000);
        //window.setInterval(console.log("hi"), 1000);
        //var BinarySearchTree = require('binary-search-tree').BinarySearchTree
        //this.treeTest = new BinarySearchTree();
        //this.treeTest.insert(20,"test")
        //this.treeTest.insert(10, "test")
        //this.treeTest.insert(30, "test")
        //console.log(this.treeTest)
        //this.treeTest.executeOnEveryNode();
        //const t = new Tree();
        //t.insert(5, "a");
        //t.insert(-10, "b");
        //t.insert(0), "c";
        //t.insert(33, "d");
        //t.insert(2, "e");
        //console.log(t.keys()); // [-10, 0, 2, 5, 33]
        //console.log(t.values()); // [-10, 0, 2, 5, 33]
        //console.log(t.size);   // 5
        //console.log(t.min());  // -10
        //console.log(t.max());  // -33
        //t.remove(0);
        //console.log(t.size);   // 4
    }

    actions = {
        // --------------------------------------
        // UI
        // --------------------------------------

        setSidebar: sidebarOpen => this.setState({ sidebarOpen }),
        setUserList: userListOpen => this.setState({ userListOpen }),

        // --------------------------------------
        // User
        // --------------------------------------

        setUser: user => this.setState({ user }),

        // --------------------------------------
        // Room
        // --------------------------------------

        //setRoom: room => {
        //    //this.setState({ room, sidebarOpen: false }) //this function sucks so im commenting it out for now
        //    this.actions.scrollToEnd()
        //},

        removeRoom: room => this.setState({ room: {} }),

        joinRoom: room => { //sets the screen to that server in particular. name should be changed eventually?
            //this.state.room = room // add room to user.rooms 
            //this.actions.subscribeToRoom(room)
            //this.state.messages[room.id]
            this.SetServer(room)
                //&&
                //this.actions.setCursor(
                //    room.id,
                //    Object.keys(this.state.messages[room.id]).pop()
                //) //fix this for proper text jumping
        },

        //subscribeToRoom: room =>
        //    //!this.state.user.roomSubscriptions[room.id] &&
        //    this.SubscribeToRoom({
        //        roomId: room.id,
        //        //hooks: { onMessage: this.actions.addMessage }, //remove for now?
        //    }),

        joinServer: serverName =>
            this.JoinServer(serverName),//.then(this.actions.joinRoom), //TODO add function in init(). 
        // shouldnt be createRoom btw, should be like join user server and put in blockstack id

        createConvo: options => {
            //if (options.user.id !== this.state.user.id) {
            //    const exists = this.state.user.rooms.find(
            //        x =>
            //            x.name === options.user.id + this.state.user.id ||
            //            x.name === this.state.user.id + options.user.id
            //    )
            //    exists
            //        ? this.actions.joinRoom(exists)
            //        : this.actions.createRoom({
            //            name: this.state.user.id + options.user.id,
            //            addUserIds: [options.user.id],
            //            private: true,
            //        })
            //}
        },

        addUserToRoom: ({ userId, roomId = this.state.room.id }) =>
            this//.state.user
                .AddUserToRoom(userId, roomId),
        //.then(this.actions.setRoom),

        removeUserFromRoom: ({ userId, roomId = this.state.room.id }) =>
            this.RemoveUserFromRoom(userId, roomId),
            //userId === this.state.user.id
            //    ? this.state.user.leaveRoom({ roomId })
            //    : this.state.user
            //        .removeUserFromRoom({ userId, roomId })
            //        .then(this.actions.setRoom),

        // --------------------------------------
        // Cursors
        // --------------------------------------

        setCursor: (roomId, position) =>
            this.state.user
                .setReadCursor({ roomId, position: parseInt(position) })
                .then(x => this.forceUpdate()),

        // --------------------------------------
        // Messages
        // --------------------------------------

        addMessage: payload => {
            const roomId = payload.room.id
            const messageId = payload.id
            // Update local message cache with new message
            this.setState(prevState => ({
                messages: {
                    ...prevState.messages,
                    [roomId]: {
                        ...prevState.messages[roomId],
                        [messageId]: payload
                    }
                }
            }))
            // Update cursor if the message was read
            if (roomId === this.state.room.id) {
                const cursor = this.state.user.readCursor({ roomId }) || {}
                const cursorPosition = cursor.position || 0
                cursorPosition < messageId && this.actions.setCursor(roomId, messageId)
                this.actions.scrollToEnd()
            }
            // Send notification
            this.actions.showNotification(payload)
        },

        runCommand: command => { //called by CreateMessageForm when parsing input for / at start
            const commands = {
                invite: ([userId]) => this.actions.addUserToRoom({ userId }),      //TODO blockstack implementation, duplicate checks
                remove: ([userId]) => this.actions.removeUserFromRoom({ userId }), //TODO blockstack implementation
                leave: ([userId]) =>
                    this.leaveServer(),//this.actions.removeUserFromRoom({ userId: this.state.user.id }), //TODO can't leave own server,
                clear: () =>
                    this.DeleteLocalChat()
            }
            const name = command.split(' ')[0]
            const args = command.split(' ').slice(1)
            const exec = commands[name]
            exec && exec(args)//.catch(console.log)
        },

        scrollToEnd: e =>
            setTimeout(() => {
                const elem = document.querySelector('#messages')
                elem && (elem.scrollTop = 100000)
            }, 0),

        // --------------------------------------
        // BlockStack Data Management
        // --------------------------------------

        //// parse json data obtained from blockstack
        //ParseBSJSON: inputBSFile => {
        //    var parsed = JSON.parse(inputBSFile);
        //    return parsed;
        //},

        //// store JSON on blockstack, do the proper conversion
        //StoreJSON: inputJSON => {
        //    var formattedJSON = JSON.stringify(inputJSON);
        //    return formattedJSON;
        //},


        // --------------------------------------
        // Typing Indicators
        // --------------------------------------

        isTyping: (room, user) =>
            this.setState(prevState => ({
                typing: {
                    ...prevState.typing,
                    [room.id]: {
                        ...prevState.typing[room.id],
                        [user.id]: true
                    }
                }
            })),

        notTyping: (room, user) =>
            this.setState(prevState => ({
                typing: {
                    ...prevState.typing,
                    [room.id]: {
                        ...prevState.typing[room.id],
                        [user.id]: false
                    }
                }
            })),

        // --------------------------------------
        // Presence
        // --------------------------------------

        setUserPresence: () => this.forceUpdate(),

        // --------------------------------------
        // Notifications
        // --------------------------------------

        showNotification: message => {
            if (
                'Notification' in window &&
                this.state.user.id &&
                this.state.user.id !== message.senderId &&
                document.visibilityState === 'hidden'
            ) {
                const notification = new Notification(
                    `New Message from ${message.sender.id}`,
                    {
                        body: message.text,
                        icon: message.sender.avatarURL,
                    }
                )
                notification.addEventListener('click', e => {
                    this.actions.joinRoom(message.room)
                    window.focus()
                })
            }
        },
    }

    componentDidMount() {
        //this.initData();
        //this.CreateServer();
        this.GetWhitelistMetadata(this.state.user.id);
        //console.log("COMPONENT MOUNT CALLED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        //this.GetAndSetMessages(this.state.room.id);
        //this.SendMessage("hi");
    }

    DeleteLocalChat() {
        //this.massive = [];
        //this.massive = []; //massive isnt being reset after deletion for some reason. cause network 404s?
        this.counter += 1;
        this.state.pendingMessages[this.state.room.id] = new Tree();
        this.massive = [];

        this.props.userSession.deleteFile("/" + this.state.room.id + "/massive.txt")
            .then(() => {
                // /hello.txt is now removed.
                //this.counter = -2 //botched
                console.log("Deleted massive?");
                this.massive = [];
                this.delStopPolling = true;
                this.GetAndSetMessages(this.state.room.id) //not updating self?... cause counter!
                    .then(() => {
                        this.delStopPolling = false;
                        this.massive = [];
                        //should ideally make like an integer constantly changed, wait until its 0 to do anything.
                    });
                //TODO: push new counter when deleting 
                //NO IDEA why clear isnt working, i totally broke something
            }).catch(() => {
                this.massive = [];
            });
       
    }

    async leaveServer(){
        if(this.state.user.room != this.state.user.id){
            let r = this.state.user.room;
            this.state.user.rooms.splice(this.state.user.rooms.indexOf(this.state.user.room), 1);
            let roomlist = "";
            this.state.user.rooms.forEach(x => {
                roomlist += x.id + " ";
            });
            roomlist = roomlist.substring(0, roomlist.length - 1);
            this.props.userSession.putFile("/serverlist.txt", roomlist, {encrypt: true});
            this.props.alert.success("Left Successfully");
            this.state.user.room = this.state.user.id;
        }
        else{
            this.props.alert.error("Cannot leave your own Server");
        }
    }

    async CreateServer() {
        let localPutOptions = {
            encrypt: false
        };
        var tempThis = this;
        var resPromise = new Promise(function (resolve, reject) {
            tempThis.props.userSession.putFile("/" + tempThis.state.user.id + "/whitelist.txt", //whitelist will be stored as a 
                tempThis.state.user.id,
                localPutOptions).then(() => {
                    //thens are stupid. but, once we put the empty massive.txt
                    //tempThis.props.userSession.putFile("/" + tempThis.state.user.id + "/massive.txt",
                    //    "",
                    //    localPutOptions).then(() => {
                            tempThis.props.userSession.putFile("/" + tempThis.state.user.id + "/counter.txt",
                                JSON.stringify(0),
                                localPutOptions).then(() => {
                                    //put final counter lol, these.thens suck
                                    resolve();

                                });


                        //});

                });
        });
        return resPromise;
    }

    //only called when joining someone for the first time. switching rooms = joinRoom function i think
    async JoinServer(serverName) {
        lookupProfile(serverName).then(x => {        
            console.log(serverName);
            //add to rooms list, set as primary room.
            //for now just add to rooms list, and fix selecting room? idk
            //check if im in the whitelist at some point
            var newRoom = {};//this.state.room;
            newRoom.id = serverName;
            newRoom.name = serverName;
            newRoom.users = [];

            let getServerOptions = {
                decrypt: false
            };
            //check if whitelist exists

            //this.AssignRoomUsers(serverName);
            //this.AssignRoomUsers(serverName);
            this.setState({ room: newRoom });
            this.GetWhitelist(serverName).then(whitelist => {
                this.AssignRoomUsers(serverName, whitelist).then(() => {
                    let exists = false;
                    this.state.user.rooms.forEach(x => {
                        if(x.id === newRoom.id){
                            exists = true;
                        }
                    })
                    if (!exists) {
                        this.state.user.rooms.push(newRoom);
                        this.props.alert.success('Joined ' + newRoom.name);
                        let roomlist = "";
                        this.state.user.rooms.forEach(x => {
                            roomlist += x.id + " ";
                        });
                        roomlist = roomlist.substring(0, roomlist.length - 1);
                        this.props.userSession.putFile("/serverlist.txt", roomlist, {encrypt: true});
                    }
                    else{
                        this.props.alert.error('Cannot join room that you are already in')
                    }

                });
            });
            this.GetAndSetMessages(newRoom.id);

            //this.props.alert.show('Attempt to join ' + serverName); //eventually check if ur on the whitelist
        }).catch(err => {
            this.props.alert.error('Invalid Server ID:\n' + serverName);
        });
    }

    SetServer(roomObj) { //for changing servers on the left side. eventually locally cache for heavy efficiency
        //var newRoom = this.state.room;
        //newRoom.id = serverName;
        //newRoom.name = serverName;
        //newRoom.users = [];

        //this.AssignRoomUsers(this.state.user.id);
        //this.setState({ room: newRoom });

        //this.state.user.rooms.push(this.state.room);
        //this.localWhitelist = [] //wipe local whitelist to use new one, might bug it?

        if (roomObj.id === this.state.room.id) {
            //if already on it
            return;
        }

        //ASSUME SERVER ALREADY EXISTS IN MEMORY!
        //console.log(serverName)
        var serverName = roomObj.id; //fucky naming, its because serverName is actually the room
        console.log(this.state.user.rooms);

        var isAlreadyHere = false;
        var alreadyHereIndex = 0;
        var counter = 0;
        this.state.user.rooms.forEach(function (room) {
            if (room.id == serverName) {
                isAlreadyHere = true;
                alreadyHereIndex = counter;
                //break; //idk how to exit this, fix from .forEach
            }
            counter += 1;
        });

        if (isAlreadyHere) {
            console.log("server name found!");
            console.log(this.state.user.rooms[alreadyHereIndex]);
                //console.log(this.state.room.id)
            this.setState({ room: this.state.user.rooms[alreadyHereIndex] }); //might bug when rapidly switching between servers?
                                                                              //users instant cause old server users were cached. where do i cache users?
            //console.log(this.state.room.id)

            //TODO: LOAD MY CACHED MASSIVE FOR THAT SERVER!
            this.massive = []

            //console.log(this.state.room.id)
            this.GetWhitelist(this.state.user.rooms[alreadyHereIndex].id).then(whitelist => { //todo: cache whitelist
                //do an if check as well for efficiency
                //console.log(this.state.room.id)
                //console.log(whitelist)
                this.AssignRoomUsers(serverName,whitelist);
            });
            //if no messages load messages
            if (!this.state.messages[this.state.room.id]) {
                this.GetAndSetMessages(this.state.room.id);
            } else {
                //if refresh with new data...?
            }

        } else {
            //doesnt exist so set it up locally
            console.log("server name NOT found!");
            this.JoinServer(serverName);
        }
        
    }

    SubscribeToRoom(data) {
        //do something with hooks like for notifications
    }

    //GetAndSetMessages(serverName) {
    //    //first get the whitelist to go through the users in the server
    //    console.log("getting messages for server: " + serverName)
    //    this.GetWhitelist(serverName).then(whitelist => {
    //        //console.log(whitelist);
    //        whitelist.split(' ').forEach(function (id) {
    //            console.log(id)
    //            //get the messsages for each id, TODO
    //        });
    //    });



    //    //todo: figure out syntax this uses for messages
    //}

    async GetWhitelist(serverName) { //should be split into different files ideally? not a big deal yet
        let localGetOptions = {
            decrypt: false,
            username: serverName
        };
        var tempProps = this.props;
        var resPromise = new Promise(function (resolve, reject) {
            tempProps.userSession.getFile("/" + serverName + "/whitelist.txt", localGetOptions).then(whitelist => {
                //console.log("tried to get whitelist of " + localGetOptions.username + " on url using " + serverName);
                //console.log(JSON.parse(whitelist));
                console.log((whitelist));
                //console.log(serverName);
                //console.log(this.state.user.id);
                resolve((whitelist)); //this is working. no need to parse bc its stored as string thankfully?
            })
        });
        return resPromise;

    }

    async GetWhitelistMetadata(serverName) { //change eventually if utilizing a room system
        let localGetOptions = {
            username: serverName
        };
        var tempProps = this.props;
        var resPromise = new Promise(function (resolve, reject) {
            tempProps.userSession.getFileUrl("", localGetOptions).then(res => {
                console.log("METADATA:")
                console.log(res)

                //var req = await
                fetch(res, { method: 'HEAD' }).then(headers => {
                    console.log(headers.headers.get('content-length')); // https://stackoverflow.com/questions/50779681/headers-not-showing-in-fetch-response
                    resolve(headers);
                });

                //var test = await fetch("https://gaia.blockstack.org/hub/1MQw6RBvTm8paEcS1VFQpqpTmM5MPijusN//billyyyy.id.blockstack/whitelist.txt");
                //var test2 = await test.headers;
                //console.log(test2); 

            });
        });
        return resPromise;
    }

    AddToWhitelist(userId) { //todo: dont allow invalid entries, display whitelist in a pretty way
            lookupProfile(userId).then(x => {
                console.log("Userid found");

        let localPutOptions = {
            encrypt: false
        };
        var a = 0;

        this.GetWhitelist(this.state.room.id).then(whitelist => {
            if (whitelist == null) {
                return;
            }
            console.log(whitelist);
            whitelist.split(' ').forEach(function (id) {
                console.log(id);
                console.log(userId);
                if (id === userId) {
                    a += 1;
                    console.error("duplicate entry in whitelist!")
                    return;
                }
            }); //end split
            if (a > 0) { //weird javascript bug, it doesnt like return
                return;
            }
            this.props.userSession.putFile("/" + this.state.user.id + "/whitelist.txt",
                whitelist + " " + userId, // we split whitelist by spaces using .split(' ') when we want to parse it somewhere else
                localPutOptions).then(() => {
                    //console.error("PUT WHITELIST ! IT IS NOW: " + whitelist + " " + userId);
                    //thens are stupid. but, once we put the whitelist we are done for now. 
                    this.AssignRoomUsers(this.state.room.id, whitelist + " " + userId);
                }).catch(() => { console.error("WHITELIST PUT ERROR FOR " + userId); this.AssignRoomUsers(this.state.room.id); });

        });
        this.props.alert.success('Successfully added ' + userId);
    }).catch(err => {
        this.props.alert.error('Invalid User ID:\n' + userId);
    });

    }




    SendMessage(message) { //assume room implicit
        // take current massive.txt
        //  append new element which is message and timestamp
        //  add onto room messages this one element as well
        // push new massive.txt to blockstack
        // increment counter
        if (!this.massive) {
            this.massive = [];
        }
        //console.log(this.state.messages);
        //console.log(this.massive);
        var timestamp = Date.now();
        //let rando = libsodium.randombytes_random(); //bad function name
        this.massive.push({
            text: message, createdAt: timestamp, sender: this.state.user
        });
        if (!this.state.messages[this.state.room.id]) {
            this.state.messages[this.state.room.id] = [];  //should render locally
        }
        //this.state.messages[this.state.room.id].push({
        //    text: message, createdAt: Date.now(),
        //    sender: {//this.state.user
        //        id: this.state.user.id,
        //    }
        //});

        console.log("PUT: " + Date.now());
        //dont push to blockstack yet?...
        this.counter += 1;

        if (!this.state.pendingMessages[this.state.room.id]) {
            this.state.pendingMessages[this.state.room.id] = new Tree();
        }

        this.state.pendingMessages[this.state.room.id].insert(timestamp, { text: message, createdAt: timestamp, sender: this.state.user }); //meh


        let localPutOptions = {
            encrypt: false
        };
        console.log("sending message on server " + this.state.room.id);
        this.props.userSession.putFile("/" + this.state.room.id + "/massive.txt", //whitelist will be stored as a 
            JSON.stringify(this.massive)//JSON.stringify(this.state.user.id)
            ,
            localPutOptions).then(() => {
                this.GetAndSetMessages(this.state.room.id)
                console.log("PUTFIN: " + Date.now());
                //todo: recovery on failure, NEED to make retrys or shit bugs out.
                //console.log("message added?")
                //console.log(this.state.messages);
                //console.log(this.massive);
                this.props.userSession.putFile("/" + this.state.room.id + "/counter.txt",
                    JSON.stringify(this.counter),
                    localPutOptions).then(() => {

                    });
        });
        
        //console.log("message added?")
        //https://stackoverflow.com/questions/45719909/scroll-to-bottom-of-an-overflowing-div-in-react
        //this.scrollToBottom();
        //var list = document.getElementById("messages");
        //list.scrollTop = list.offsetHeight * 20 + list.offsetHeight; //does NOT work properly???
        this.sentNewMessage = true;
        //console.log(list.offsetHeight)
        //this.forceUpdate();
        this.setState({ pendingMessages: this.state.pendingMessages }, () => {
            var list = document.getElementById("messages");
            list.scrollTop = list.offsetHeight * 20 + list.offsetHeight; //botched way to scroll down lol but it works every time probably
        });



    }

    //get every user's message and add it to the room, and the local map as well
    async GetAndSetMessages(serverName) {
        //console.log(this.state.room.users)
        //console.log(this.state.room)
        //console.log(this.localWhitelist)
        //use local whitelist eventually, but for now just keep polling the whitelist file
        var tempRoomId = this.state.room.id;
        var tempMassive = this.state.massive;
        var tempCounter = this.counter;
        //console.log(tempMassive)
        //console.error("WHITELIST")
        console.log(this.localWhitelist)
        if (tempMassive === null || tempMassive === undefined) {
            tempMassive = []
        }
        var tempUserID = this.state.user.id;
        var tempProps = this.props; //idk if this gets modified?
        //var tempState = this.state;
        var tempThis = this;
        this.GetWhitelist(serverName).then(whitelist => { //unecessary! remove later!
            //var temp = [];
            var t = new Tree();
            //get my own massive.txt first, store it in variable this.massive
            //let localGetOptions = {
            //    decrypt: false
            //};
            //this.props.userSession.getFileUrl("/" + this.state.room.id + "/massive.txt", localGetOptions).then(myMassive => { 
                //this.massive = myMassive;
                ////temp.push(myMassive); //for each message, push to array
                //myMassive.forEach(function (myMessage) {
                //    temp.push(myMessage);
                //});
            var finalPromise = new Promise(function (resolve, reject) { 
                var localCounter = 0;
                if (whitelist == null) {
                    resolve();
                }
                var whitelistCounter = 0;
                var whitelistLen = whitelist.split(' ').length;
                whitelist.split(' ').forEach(function (id) {
                    
                    //for each user in server
                    //get massive.txt, add to array, add to map variable for this
                    let localOtherGetOptions = {
                        decrypt: false,
                        username: id //string parse is correct?
                    };
                    tempThis.GetUserCounter(id).then((userCounter) => {
                        //console.log(tempThis.localWhitelist);
                        //console.log(id);
                        //console.log(id === "sadasasdasasd.id.blockstack");
                        //console.log(id === "billyyyy.id.blockstack");
                        //console.log(tempThis.localWhitelist[id]);
                        //console.log(tempThis.localWhitelist[id].value);
                        //console.log(tempThis.localWhitelist[id].value.counter);
                        var counterSkip = false
                        if (id === '') {
                            localCounter += 1;
                            counterSkip = true;
                        }
                        //if (tempThis.localWhitelist[id]) {
                        //    if (userCounter === tempThis.localWhitelist[id].value.counter
                        //        && userCounter != -1 //error prevention from stalling
                        //    ) {
                        //        //same counter, ignore
                        //        counterSkip = true;
                        //        console.log("SKIPPING!!!!!!!!!!!!!")
                        //    }
                        //}
                        if (!counterSkip) {
                                //console.log("NOT SKIPPING!!!!!!!!!!!!!")
                            //counter is different, update our local state
                            if (tempThis.localWhitelist[id]) {
                                tempThis.localWhitelist[id].value.counter = userCounter; //ignore if local maybe?
                            }
                            if (!tempThis.localWhitelist[id]) {
                                return;
                            }
                            //tempProps.userSession.getFile("/" + tempRoomId + "/massive.txt", localOtherGetOptions).then(userMassive => {
                            fetch(tempThis.localWhitelist[id].value.fileUrl + "/" + tempRoomId + "/massive.txt", { method: 'GET' }).then(userMassive => {
                                userMassive.text().then((text) => { //get text of result... NEED TO CHECK FOR FAILURE BETTER FUCK! REFACTOR THIS WHOLE DAMN THING
                                    console.log("attempt get data " + id)
                                    //todo push to local map
                                    //todo check file size for validity (not here, eventually on legit scenario)
                                    localCounter += 1;
                                    var doSkip = false;
                                    //if (tempThis.delStopPolling) {
                                    //    doSkip = true;
                                    //}

                                    if (id == tempUserID) { //if whitelist is us
                                        //console.log(tempThis.massive)
                                        if (tempMassive.length !== 0
                                            && (tempCounter === tempThis.counter) //if counters the same, no new message.
                                        ) { //compare with current massive, if different dont update cause its fucky.  
                                            //could store a local counter to check for message differences?
                                            //console.log("SKIPPING cause user !!!!!!!!!!!!!")
                                            //doSkip = true;
                                            //tempMassive.forEach(function (tempMessage) {
                                            //    t.insert(tempMessage.createdAt, tempMessage); //should NOT be fetching! 
                                            //});
                                        }
                                        if (userMassive.status === 200 && (tempMassive.length === 0 && tempThis.massive.length === 0)
                                            && tempRoomId === tempThis.state.room.id //check rooms havent changed to prevent bugs
                                        ) {
                                            if (!tempThis.delStopPolling) { //prevents sendmessage looking shitty
                                                //console.error(1)
                                                //console.error(userMassive)
                                                //console.log(userMassive.body)

                                                //console.error(userMassive.json())
                                                //console.error(text)
                                                tempThis.massive = JSON.parse(text); //set our massive
                                                console.log("got massive for me!"); //PROBLEM: wont parse if multi machine. 
                                                console.log(tempThis.massive); //PROBLEM: wont parse if multi machine. 
                                            }
                                            //SOLUTION: counter should ignore this and reset the chat
                                            //TODO!
                                        }
                                        //console.log(userMassive);
                                    }
                                    //console.log(userMassive);

                                    if (userMassive === null) {
                                        doSkip = true;
                                    }
                                    if (!doSkip) {
                                        //console.log(JSON.parse(userMassive));
                                        //console.error(2)
                                        //console.error(userMassive)
                                        if (userMassive.status === 200) { //only parse if its not a dead link
                                            JSON.parse(text).forEach(function (myMessage) { //for each message in massive
                                                //temp.push(myMessage); //add to room messages
                                                t.insert(myMessage.createdAt, myMessage);
                                            }); //https://www.npmjs.com/package/splaytree
                                        }

                                    }
                                    //console.log("final check: " + localCounter + whitelistCounter);
                                    if ((localCounter + whitelistCounter) === whitelistLen
                                        || localCounter === whitelistLen
                                    ) {
                                        resolve(); //only finish once every whitelist person is iterated and accounted for in the chat log
                                    }
                                });
                            }).catch((err) => {
                                whitelistCounter += 1;
                                console.error("skipping invalid/unknown id: " + id);
                                console.error(err);
                                console.log(localCounter)
                                console.log(whitelistCounter)
                                if ((localCounter + whitelistCounter) === whitelistLen) { //&& 
                                    //whitelist.split(' ')[whitelistLen - 1] === id) { //check if last
                                    resolve();
                                }
                            });
                        }
                    });
                });
                
            });
                //set room.messages to array obtained from polling everyone
                //this.setState({
                //    room: {
                //        ...this.state.room,
                //        messages: temp
                //    }
                //});
                //sort by date somehow, or is it done implicitly?
            finalPromise.then(() => {
                //console.log("promise resolved!")
                //console.log(this.state.room.id === tempRoomId)
                //console.log(this.state.room.id)
                if (this.state.room.id === tempRoomId) {

                    //remove duplicates between pending and existing messages]
                    //var treeRes = t.values();
                    if (!this.state.pendingMessages[this.state.room.id]) {
                        this.state.pendingMessages[this.state.room.id] = new Tree();
                    }
                    

                    var treeRes = t.values();

                    console.log(treeRes);
                    this.state.messages[this.state.room.id] = treeRes;

                    this.state.pendingMessages[this.state.room.id].values().forEach(pendingMessage => {
                        //console.log(t.find(pendingMessage.createdAt))
                        //console.log(pendingMessage)
                        //console.log(this.state.pendingMessages[this.state.room.id].values())
                        if (t.find(pendingMessage.createdAt)) { //will definitely bug on identical values, but currently not a concern
                            //console.log("removed!");
                            this.state.pendingMessages[this.state.room.id].remove(pendingMessage.createdAt);
                        }
                    });
                    //for (var i in this.state.pendingMessages[this.state.room.id].values()) {
                    //    //if value in 
                    //    console.log(t.find(i.createdAt))
                    //    console.log(i)
                    //    console.log(this.state.pendingMessages[this.state.room.id].values())
                    //    if (t.find(i.createdAt)) { //will definitely bug on identical values, but currently not a concern
                    //        console.log("removed!");
                    //        this.state.pendingMessages[this.state.room.id].remove(i.createdAt);
                    //    }
                    //}
                    //this.state.messages[this.state.room.id] = temp;  // massives are implicitly stored correctly thru sendmessage
                    //console.log(this.state.messages);
                    //console.log(t.keys())
                    //console.log(t.values())
                    this.forceUpdate();
                }
            });
            return finalPromise;
                //this.state.messages[this.state.room.id].push({ text: message, createdAt: Date.now(), sender: this.state.user });
            //});
        });
        //console.log(this.state.messages);
    }

    async GetUserCounter(userID) {
        let localOtherGetOptions = {
            decrypt: false,
            username: userID //string parse is correct?
        };
        var tempThis = this;
        var resPromise = new Promise(function (resolve, reject) {
        console.log("getting user counter of " + userID)
            tempThis.props.userSession.getFile("/" + tempThis.state.room.id + "/counter.txt", localOtherGetOptions).then(userCounter => {
            //fetch(tempThis.localWhitelist[userID].value.fileUrl + "/" + tempThis.state.room.id + "/counter.txt", { method: 'GET' }).then(userCounterRes => {
                //userCounterRes.text().then((userCounter) => { //this could work eventually but we get counters with the whitelist url so...
                    try {
                        console.log("success 1 " + userID)
                        resolve(JSON.parse(userCounter));
                    } catch{
                        console.error("failure 1 " + userID)
                        resolve(-1);
                    }
                }).catch(() => {
                    console.error("failure 2 " + userID)
                    resolve(-1);
                });
            //});
        });
        return resPromise;
    }

    //add each user in the whitelist as a user in the room
    async AssignRoomUsers(serverName, inputWhitelist) {
        console.error("assinging users to room...")
        //this.localWhitelist = {};
        var localWhitelist = {};
        var tempThis = this;
        var tempProps = this.props;
        

        var resPromise = new Promise(function (resolve, reject) {
            var whitelist = inputWhitelist
            //tempThis.GetWhitelist(serverName).then(whitelist => {
                //console.error("GOT WHITELIST OF IN ASSIGN:")
                //console.log(whitelist)
                var temp = [];
                if (whitelist === null) {
                    return;
                }

                var tempCounter = 0;
                var extraCounter = 0;
                var tempLen = whitelist.split(' ').length;
            whitelist.split(' ').forEach(function (id) {
                var badSkip = false;
                if (id === '') {
                        tempCounter += 1;
                        badSkip = true;
                }
                //lookupProfile(id).then(x => { //TODO: fix when whitelist has invalid users, should ignore them.
                //}).catch(() => {
                //    tempCounter += 1;
                //    badSkip = true;
                //});
                if (!badSkip) {
                    //define user JSON obj
                    var resUser = {};
                    //temp set to online
                    resUser.presence = {};
                    resUser.presence.state = "online";
                    //temporarily set name to blockstack id
                    resUser.name = id;
                    resUser.id = id;
                    temp.push(resUser);

                    var localGetOptions = {
                        decrypt: false,
                        username: id
                    }

                    tempProps.userSession.getFileUrl("", localGetOptions).then(fileUrl => {
                        tempThis.GetUserCounter(id).then((userCounter) => {
                            console.log("got counter for " + id)
                            localWhitelist[id] = {
                                value: {
                                    //...tempThis.localWhitelist[id].values,
                                    counter: userCounter,
                                    fileUrl: fileUrl
                                    //since im wiping it theres no reason to save old values
                                    //eventually save it, or just save into a different structure-
                                    //to do diffs n shit
                                }
                            };
                            tempCounter += 1;
                            if (tempCounter === tempLen || (extraCounter + tempCounter) === tempLen) {
                                //if we finished rebuilding
                                //set the whitelist again
                                tempThis.localWhitelist = localWhitelist;
                                tempThis.setState({
                                    room: {
                                        ...tempThis.state.room,
                                        users: temp
                                    }
                                });
                                console.log("successfully assigned users to room")
                                resolve()
                            }
                            //tempThis.localWhitelist[id]
                            //tempThis.localWhitelist.push({
                            //    key: resUser.id,
                            //    value: {
                            //        //get counter?
                            //        counter: userCounter
                            //        //maybe diff data here
                            //    }

                            //});
                            //resUser.id
                        }).catch(() => {
                            console.error("whitelist error! " + id)
                            extraCounter += 1;
                            if ((extraCounter + tempCounter) === tempLen) {
                                tempThis.localWhitelist = localWhitelist;
                                tempThis.setState({
                                    room: {
                                        ...tempThis.state.room,
                                        users: temp
                                    }
                                });
                                console.log("successfully assigned users to room?")
                                resolve()
                            }
                        }); //end of counter get
                    }); //end of fileget
                } //after badSkip if
            }); //end of whitelist loop
                //resolve(); //shouldnt resolve cuz it failed but...
            //});
        });
        return resPromise;
    }

    AddUserToRoom(userId, roomId) {
        console.log("adding user to room: " + userId);
        //console.log(userId)
        this.AddToWhitelist(userId); //todo add to specific rooms not just yours
        //shouldnt stringify but i think its not a string?...
    };

    RemoveUserFromRoom(userId, roomId) { //only for server host for now
        if (roomId !== this.state.user.id || userId === roomId) {
            return;
        }
        var tempThis = this;
        //get whitelist
        //reconstruct without userId
        //replace whitelist with new constructed whitelist
        this.GetWhitelist(roomId).then(whitelist => {
            if (whitelist == null) {
                return;
            }
            //if (roomId !== this.state.user.id) {
            //    return;
            //}
            //reconstruct whitelist while ignoring roomId
            var resStr = "";
            var initial = 0;
            whitelist.split(' ').forEach(function (id) {
                if (id !== userId && id !== "") { //skip shitty spaces
                    if (initial === 0) {
                        initial += 1;
                        resStr += id + " "; //edge case
                    } else {
                        resStr += " " + id;
                    }
                }
            });

            let localPutOptions = {
                encrypt: false
            };
            //put new file
            console.error(resStr)
            tempThis.props.userSession.putFile("/" + tempThis.state.user.id + "/whitelist.txt", //whitelist will be stored as a 
                resStr,
                localPutOptions).then(() => {
                    //should to promise, on failure retry?... need to theorize retry system better
                    //resolve();
                    this.props.alert.success('Successfully removed ' + userId + " from " + roomId);
                    if (roomId === this.state.user.id) {
                        this.AssignRoomUsers(tempThis.state.user.id, resStr);
                    }
                    //todo: alerts on success/failure?
            });
        });
    }

    PollRoomUpdate() {
        var tempRoom = this.state.room.id
        var tempThis = this;

        this.GetWhitelist(tempRoom).then(whitelist => {
            if (tempRoom !== this.state.room.id) { //make sure same room
                //update userlist
                tempThis.AssignRoomUsers(this.state.room.id, whitelist);
            }
        });

    }



    ////
    
    AddServerToConfig() {
        //called usually on joinserver, add a new server to our server config
    }

    RemoveServerFromConfig() {

    }

    GetAndAssignServers() {
        //read config and get servers ive connected to
    }

    //helpers:
    //dont use local data, use regular gets to be safe
    async GetServerConfig() {
        let localGetOptions = {
            decrypt: false
        };
        var tempThis = this;
        var resPromise = new Promise(function (resolve, reject) {
            tempThis.props.userSession.getFile("/" + tempThis.state.user.id + "/config.txt", 
                localGetOptions).then((res) => {
                    resolve(res);
                });
        });
        return resPromise;
    }

    ////



    //todo: fix icons/text for adding servers, icon for send message
    initData() { // focus on replacing existing functionality for now.

        // assign blockstack id to user.id
        //var newUser = this.state.user;
        //var tempThis = this;
        //setTimeout(function () { tempThis.state.user.id = tempThis.props.userSession.loadUserData().username; }, 5000);
        this.state.user.id = this.props.userSession.loadUserData().username; //not sure if efficiency is a thing
        //this.state.user.id = null;
        if (this.state.user.id !== null) {
            var tempThis = this;
            window.setInterval(function () { //hope this doesnt fuck up if component is mounted twice?
                tempThis.GetAndSetMessages(tempThis.state.room.id)
                //console.log("sup")
                //fetch("gorilla.chat", { method: 'HEAD' }).then(headers => {
                //    //console.log(headers.headers.get('content-length')); // https://stackoverflow.com/questions/50779681/headers-not-showing-in-fetch-response
                //    //resolve(headers); 
                //    //WOW! FETCHES USE ONLY ~17% CPU ON BROWSER WHEN 100 PER SECOND! WOW! granted im spamming HEAD but still
                //});
            }, 1000);
            window.setInterval(function () { //hope this doesnt fuck up if component is mounted twice?
                tempThis.PollRoomUpdate()
            }, 4000);
        } else {
            console.error("CRITICAL ERROR! USERNAME/ID IS NULL! related data below:")
            console.error("props:")
            console.log(this.props)
            console.error("props.userSession:")
            console.log(this.props.userSession)
            console.error("props.userSession.loadUserData():")
            console.log(this.props.userSession.loadUserData());
            console.error("props.userSession.loadUserData().username:")
            console.log(this.props.userSession.loadUserData().username);
            console.error("props.userSession.loadUserData() data:")
            console.log(this.props.userSession.loadUserData().hubUrl);
            console.log("identityAddress:" + this.props.userSession.loadUserData().identityAddress);
            console.log("decentralizedId:" + this.props.userSession.loadUserData().decentralizedID);
            //console.log(this.props.userSession.loadUserData().sfdfdslkjsdljds);
            console.error("props.userSession configs...:")
            console.log(this.props.userSession.appConfig.appDomain);
            console.log(this.props.userSession.appConfig.authenticatorURL);
            console.log(this.props.userSession.store.key);
            console.error("checking if the app is in the user's loaded data:")
            console.log(this.props.userSession.loadUserData().profile.apps["https://gorilla.chat"]);
            console.error("is user signed in?:")
            console.log(this.props.userSession.isUserSignedIn());
            console.error("is user signed in pending?:")
            console.log(this.props.userSession.isSignInPending());


            //console.error("foreach in props.userSession.loadUserData():")
            //for (var i in this.props.userSession.loadUserData()) {
            //    console.log(i)
            //}
            //console.error("foreach in props:")
            //for (var i in this.props) {
            //    console.log(i)
            //}
            //console.error("foreach in props.userSession:")
            //for (var i in this.props.userSession) {
            //    console.log(i)
            //}
            //console.error("state:")
            //console.error(this.state)
            return;
        }
        this.state.user.roomSubscriptions = [];//?
        this.state.user.rooms = [];//?
        //assign sendMessage function
        var tempThis = this;
        this.state.user.sendMessage = function (data) { //todo: remove this make standalone function
            //console.log("todo send message");
            tempThis.SendMessage(data);
            //console.log(this.GetWhitelist(newUser.id));
        };
        //newUser.AddToWhitelist = this.AddToWhitelist;
        //newUser.addUserToRoom = async function (userId, roomId) {
        //    console.log("adding user to room!");
        //    this.AddToWhitelist(userId) //todo add to specific rooms not just yours
        //};
        //this.setState({ user: newUser });
        

        // set default server to user's server. AKA home server
        var newRoom = this.state.room;
        //this.state.user.id = null;
        newRoom.id = this.state.user.id;
        newRoom.name = this.state.user.id;
        let getServerOptions = {
            decrypt: false
        };

        // if server doesn't exist, create it 
        //var tempBool = false
        this.props.userSession.getFile("/" + this.state.user.id + "/whitelist.txt", getServerOptions).then(whitelist => {
            if (whitelist === null) {
                //create server
                this.CreateServer().then(() => { //assumes the post doesnt fail, still a possibility -- i never retry... do a timeout?
                    // get and set users for home server (see UserList)
                    this.props.userSession.getFile("/" + this.state.user.id + "/whitelist.txt", getServerOptions).then(newWhitelist => {
                        newRoom.users = []; //instantiate empty list, adding happens in UserList component
                        //get rooms from data file on blockstack, encrypted private settings?
                        this.setState({ room: newRoom });

                        this.AssignRoomUsers(this.state.user.id, newWhitelist).then(() => {
                            this.state.user.rooms.push(this.state.room);
                            this.GetAndSetMessages(newRoom.id);
                        });
                    });
                });
            } else {
                //it exists already so TODO: get messages?
                //this.CreateServer();
                //this.GetAndSetMessages(newRoom.id);
                newRoom.users = [];
                this.setState({ room: newRoom });
                this.AssignRoomUsers(this.state.user.id, whitelist).then(() => {
                    this.state.user.rooms.push(this.state.room);
                    this.GetAndSetMessages(newRoom.id);
                    this.props.userSession.getFile("/serverlist.txt", {decrypt:true}).then(serverlist => {
                        if(serverlist){
                            serverlist.split(' ').forEach(x => {
                                if(x != this.state.user.id){
                                    this.state.user.rooms.push({
                                        id: x,
                                        name: x,
                                        users: []
                                    });
                                }
                            });
                        }
                    });
                });
            }
            
        });
        
        //newRoom.users = [];
        //this.AssignRoomUsers(this.state.user.id).then(() => {
        //    this.state.user.rooms.push(this.state.room);
        //    this.GetAndSetMessages(newRoom.id);
        //});
        

}

    //renderSomeJSX(user, createConvo, messages, room) {
    //    //this.scrollToBottom();
    //    //var list = document.getElementById("messages");
    //    //if (list && this.sentNewMessage) {
    //    //    this.sentNewMessage = false;
    //    //    console.error(11111111)
    //    //    list.scrollTop = list.offsetHeight * 20 + list.offsetHeight; //does NOT work properly???
    //    //}
    //    return (
    //        <MessageList
    //        wtf={user} //https://www.freecodecamp.org/forum/t/reactjs-how-to-assign-an-jsx-element-to-a-variable-and-use-it-in-render-method/230442/4
    //        user={user} //^ render this as func call, scroll to bottom if pending has values
    //        messages={messages[room.id]}
    //        createConvo={createConvo}
    //        pendingMessages={this.state.pendingMessages[room.id]}
    //        forPending={true}
    //        forNormal={false}

    //        />
    //    );
    //}

  render() {
    const {
      user,
      room,
      messages,
      typing,
      sidebarOpen,
      userListOpen,
    } = this.state
      const { joinServer, createConvo, removeUserFromRoom } = this.actions
      //console.log(this.props.userSession.loadUserData());
      //console.log(blockstack.lookupProfile('domdomdom.id.blockstack'));

      //if (messages[room.id]) {
      //    this.state.messages[room.id] = messages[room.id].concat(this.state.pendingMessages[room.id])
      //    //messages[room.id] = [];
      //}
      const shit = 1;

    return (
      <main>
            <aside data-open={sidebarOpen} style={{borderWidth:'0px'}}>
          <UserHeader user={user} />
          <RoomList
            user={user}
            rooms={user.rooms}
            messages={messages}
            typing={typing}
            current={room}
            actions={this.actions}
          />
          {user.id && <CreateRoomForm submit={joinServer} />}
        </aside>
        <section>
          <RoomHeader state={this.state} actions={this.actions} />
          {room.id ? (
            <row->
              <col->
                            <MessageList
                                wtf={user} //https://www.freecodecamp.org/forum/t/reactjs-how-to-assign-an-jsx-element-to-a-variable-and-use-it-in-render-method/230442/4
                                user={user} //^ render this as func call, scroll to bottom if pending has values
                                messages={messages[room.id]}
                                createConvo={createConvo}
                                pendingMessages={this.state.pendingMessages[room.id]}
                                forPending={true}
                                forNormal={false} //{this.renderSomeJSX(user, createConvo, messages, room)}
                                
                            />
                            
                <TypingIndicator typing={typing[room.id]} />
                <CreateMessageForm state={this.state} actions={this.actions} />
              </col->
              {userListOpen && (
                <UserList
                  room={room}
                  current={user.id}
                  createConvo={createConvo}
                  removeUser={removeUserFromRoom}
                />
              )}
            </row->
          ) : user.id ? (
            <JoinRoomScreen />
          ) : (
            <WelcomeScreen />
          )}
        </section>
      </main>
    )
  }
}

export default withAlert()(View);

// // --------------------------------------
// // Authentication
// // --------------------------------------

// window.localStorage.getItem('chatkit-user') &&
  // !window.localStorage.getItem('chatkit-user').match(version) &&
  // window.localStorage.clear()

// const params = new URLSearchParams(window.location.search.slice(1))

// 1==1
  // ? ReactDOM.render(<View />, document.querySelector('#root'))
  // : ReactDOM.render(<View />, document.querySelector('#root'))
