import React from 'react'
import style from './index.module.css'
import Linkify from 'react-linkify'

const time = string => {
  const date = new Date(string)
  const minutes = date.getMinutes()
  return `${date.getHours()}:${minutes < 10 ? '0' + minutes : minutes}`
}

class Attachment extends React.Component {
  componentDidMount() {
    if (this.props.link) this.setState({ src: this.props.link });
  }
  render() {
    return this.state
      ? {
          image: (
            <img controls={true} src={this.state.src} alt={this.state.name} />
          ),
          video: <video controls={true} src={this.state.src} />,
          audio: <audio controls={true} src={this.state.src} />,
          file: (
            <a href={this.state.src} download>
              Download File
            </a>
          ),
        }[this.props.type]
      : null
  }
}

/*<img
        onClick={e => createConvo({ user: message.sender })}
        src={message.sender.avatarURL}
        alt={message.sender.name}
      />
      */

export const Message = ({ user, createConvo, forPending }) => message => // THE NAMING HAS TO BE CONSISTENT BETWEEN PASS IN! UGH 
  message.sender ? (
        <li key={message.id} className={style.component}
            style={{ borderColor: '#136c3e' }}
        >
      
            <div>
                <span style={{color:'#2ecc69'}}
          className={
            message.sender.id === user.id ||
            (message.sender.presence &&
              message.sender.presence.state === 'online')
              ? style.online
              : null
          }
                >{`${message.sender.id}`}<span style={{ color: '#bebbc3' }}>&nbsp;{time(message.createdAt)}</span></span>
                <p>
                    
                    {forPending ? (

                        <span style={{ color: '#bebbc3' }}>{message.text}</span>
                        )
                        :
                        <span style={{ color: '#e5e4e7' }}>{message.text}</span>
                    }
                    
        </p>
        {message.attachment ? (
          <Attachment
            user={user}
            link={message.attachment.link}
            type={message.attachment.type}
          />
        ) : null}
      </div>
    </li>
    ) : console.log({ message })

/*
 
 <Linkify properties={{
                        target: '_blank',
                        style: { color:'#1cb0f4'}
                    }} >

removed cause colors dont work and text looks like shit
 */