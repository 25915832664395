import React from 'react'
import style from './index.module.css'
import upArrow from './../../../images/up-arrow.svg';


function onChange(e) {
    console.log("invalid input")
    e.target.setCustomValidity("Please input the blockstack ID of the server.");
}
function submitFail(e, tex) {
    e.setCustomValidity(tex);
}
function clearr(e) {
    e.target.setCustomValidity("");
}
//function test(e) {
//    if (e.target.value != "dad") {
//        e.target.setCustomValidity("Please input a valid Blockstack ID.");
//    } else {
//        e.target.setCustomValidity("RAD");
//    }
//}

export const CreateRoomForm = ({ submit }) => (
  <form
        className={style.component}
        style={{
            zIndex: '7',
            //height: '58px'
            backgroundColor: '#7e5135',
            borderRight: ' 1px solid #482e1e',
            borderTop: '0px'
        }}
        //onInvalid={this.setCustomValidity('Witinnovation')}
        onInvalid={onChange}
        onChange={clearr}
        //onValid={onChange}
        onInput={clearr}
        noValidate
        //noValidate
    onSubmit={e => {
        e.preventDefault()
        //e.currentTarget.setCustomValidity("Please input the blockstack ID of the server.");
        //console.log(e)
        //setCustomValidity("Please input the blockstack ID of the server.")
        //console.log(JSON.stringify(e.target[0].value).split('.').length)
        ////e.target[0].value = ''
        //if (JSON.stringify(e.target[0].value).split('.').length < 2 || JSON.stringify(e.target[0].value).split('.').length > 3) {
        //    //console.log("ass")
        //    //console.log(JSON.stringify(e.target[0].value).split('.').length < 2 || JSON.stringify(e.target[0].value).split('.').length > 4)
        //    //console.log(JSON.stringify(e.target[0].value).split('.').length < 2)
        //    //console.log(JSON.stringify(JSON.stringify(e.target[0].value).split('.').length > 4))
        //    e.target[0].placeholder = 'please input a valid id'
        //    submitFail(e.target[0], "invalid blockstack id");
        //    e.target[0].setCustomValidity("Please input the blockstack ID of the server.");
        //} else {

        //    submit(e.target[0].value//{
        //        //name: e.target[0].value,
        //        //private: e.target.elements[2].checked,
        //        //}
        //    )
        //    e.target[0].placeholder = 'serverusername.id.blockstack'
        //}
        submit(e.target[0].value)
        e.target[0].value = ''
        }} //<p style={{ minWidth: '27%', fontSize: '16px' }}>Join Server:</p> //{paddingLeft: '5%' }
    >
        
        <input
            style={{
                backgroundColor: 'rgba(244, 244, 244, 1)',
                paddingTop: '6px',
                marginLeft: '6px',
                paddingBottom: '6px',
                borderRadius: '10px 10px 10px 10px',
                cursor: 'text'
            }}
            //minLength='4'
            pattern="^\w+(\.\w+){1,2}$"
            onInput={clearr}
            required
            placeholder="serverusername.id.blockstack" type="text" required />

        
        <button style={{ outline: 'none', backgroundColor: 'Transparent', margin: '0px', padding: '0px' }}>
            <img src={upArrow} style={{ maxWidth: '30px', height: 'auto', padding: '0px', marginRight: '2px', cursor: 'pointer' }} //todo make button
            >
            </img>
        </button>
  </form>
)
