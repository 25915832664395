import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React, { useState, useEffect } from 'react';
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import ProductCTA from './modules/views/ProductCTA';
import AppAppBar from './modules/views/AppAppBar';
import View from './chatclient/View';
import Terms from './Terms';
import Link from '@material-ui/core/Link';
import Typography from '../onepirate/modules/components/Typography';
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
//import {
//    UserSession,
//    AppConfig
//} from 'blockstack';
import * as blockstack from 'blockstack'
import Privacy from './Privacy';

const appConfig = new blockstack.AppConfig(['store_write', 'publish_data']);
const userSession = new blockstack.UserSession({ appConfig: appConfig });

const alertOptions = {
  timeout: 5000,
  position: positions.BOTTOM_RIGHT
};

function BlockstackSignIn(e) {
    e.preventDefault();
    //userSession.redirectToSignIn();
    userSession.redirectToSignIn(window.location.origin + "/app");
}

function BlockStackSignOut(e) {
  e.preventDefault();
  userSession.signUserOut(window.location.origin);
}

function Index() {
  const [userData, setUserData] = useState();
  const [inApp, setInApp] = useState();

    console.log(appConfig.appDomain)
    //if (!userSession.loadUserData().username) {
    //    alert('Gorilla Chat requires a username! We can\'t access databases without it!')
    //}

  useEffect(() => {
    //console.log("hellooooooo");
      setInApp(window.location.pathname === "/app");
      if (userSession.isSignInPending()) {
        userSession.handlePendingSignIn().then(x => {
            //console.log("got usersession data!: " + x)
            //if (!x.username) {
            //    throw new Error('Gorilla Chat requires a username! We can\'t access databases without it!')
            //}
            //setUserData(x);
            window.history.replaceState({}, document.title, "/app");
            setUserData(x);
        //console.log(document.title);
        //window.location.replace("/app");
        
        
      });
    }
    });

    /*
              <ProductCategories />
              <ProductHowItWorks />
              <ProductCTA />
              <ProductSmokingHero />
              <AppFooter />
              */
  return (
      <>
      <AppAppBar session={userSession} appStatus={inApp} signin={BlockstackSignIn} signout={BlockStackSignOut} />
          {userSession.isUserSignedIn() && !userSession.loadUserData().username &&
              <p style={{ color: 'red', backgroundColor: 'black', textAlign: 'center' }}>
                  <b>YOU MUST HAVE A USERNAME TO USE THIS APP!</b>
                    <br />
              LOGGED-IN USER IDENTITY ADDRESS: {userSession.loadUserData().identityAddress}
              
                  <br />
              <b>PLEASE GO TO <Link style={{ color: 'blue' }} href="https://browser.blockstack.org/profiles/i/all">blockstack.org </Link>
                  TO ADD A USERNAME FOR YOUR ACCOUNT!</b>
              </p>
          }
          <Router >
              <Route exact path="/">
              <ProductHero />
              <ProductValues />
              <AppFooter />
              
        </Route>
        <Route path='/privacy'>
          <Privacy/>
        </Route>
              <Route path={"/sign-in"}>
              <button onClick={BlockstackSignIn}>
                  test
              </button>
              hi
        </Route>
              
            <Route path="/terms">
              <Terms />
            </Route>
              <Route path="/help">
                  <p style={{ backgroundColor: '#d2eeb7', height: '95vh', textAlign: 'left', paddingLeft:'40px' }}><br />
                      <Typography variant="h4">
                          <u>IMPORTANT INFORMATION ABOUT GORILLA CHAT:</u>
                          {''}
                      </Typography>
                      <Typography variant="h6">
                          <u>INSTRUCTIONS (temporary):</u>
                      </Typography>
                      <Typography variant="h5">
                          &nbsp;&nbsp;&nbsp;&nbsp;{'- When you first launch Gorilla Chat, a \'server\' will be created under your name.'}
                      </Typography>
                      <Typography variant="h5">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'- In order for people to join your server, you must first invite them.'}
                      </Typography>
                      <Typography variant="h5">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'- Likewise, others must invite you before you can join their server.'}
                      </Typography>
                      <Typography variant="h5">
                          &nbsp;&nbsp;&nbsp;&nbsp;{'Once you\'re connected, chat away!'}
                      </Typography>
                      <br />
                      <Typography variant="h5">
                          We are currently<span style={{color:'red'}}> VERY EARLY </span>in development.
                      </Typography>
                      <Typography variant="h5">
                          {'This means loads of missing (but planned) features, UI improvements, and the like.'}
                      </Typography>
                      <Typography variant="h5">
                          {'But we\'re excited to show you what we\'ve done so far, and what we plan to do next!'}
                      </Typography>
                      <br />
                      <Typography variant="h6">
                          <u>FEATURES</u>
                      </Typography>
                      <Typography variant="h6">
                          {'CURRENTLY IMPLEMENTED'}
                      </Typography>
                          <Typography variant="h5">
                          {'|- Fully decentralized  (Gorilla Chat can\'t be shut down or controlled, and is always online)'}
                          </Typography>
                          <Typography variant="h5">
                              {'|- Self-hosted data ownership'}
                          </Typography>
                          <Typography variant="h5">
                              {'|- Group Text Chat! (entirely through Blockstack)'}
                          </Typography>
                          <Typography variant="h5">
                          {'|- A very '}<i>beautiful </i>interface.
                          </Typography>
                      <br />
                      <Typography variant="h6">
                          {'PLANNED (in no particular order)'}
                      </Typography>
                          <Typography variant="h5">
                              {'|- P2P voice chat, video chat, and screen sharing'}
                          </Typography>
                          <Typography variant="h5">
                              {'|- Unread notifications, Android/IOS builds'}
                          </Typography>
                          <Typography variant="h5">
                          {//'|- Encrypted text chat (yeah we know, it\'s coming up soon!)'}
                          }
                          </Typography>
                          <Typography variant="h5">
                          {//'|- Join servers without 2-way handshake'}
                          }
                          </Typography>
                              <Typography variant="h5">
                          {//'|   - essentially, the server has to do nothing to accept a user'}
                          }
                              </Typography>
                          
                          <Typography variant="h5">
                              {'|- Blockstack or P2P/Bittorrent based file sharing'}
                          </Typography>
                          <Typography variant="h5">
                              {'|   - Easily share UNLIMITED files with NO FILE LIMIT, privately and securely!'}
                          </Typography>
                          <Typography variant="h5">
                              {'|- Custom Icons, gifs, emojis, link/embed support, lots of customization'}
                          </Typography>
                          <Typography variant="h5">
                              {'|- and of course, lots of bug fixes and UI improvements!'}
                          </Typography>
                      <br />
                  </p>
              </Route>
        <Route path="/sign-up">
          <button on onClick={BlockStackSignOut}>
            sign out
          </button>
        </Route>
        <Route path="/app">
          <Provider template={AlertTemplate} {...alertOptions}>
          {!userSession.isUserSignedIn() && userSession.isSignInPending() && <div>Pending login. If you're stuck here, enable 3rd party cookies and try again.</div>}
          {!userSession.isUserSignedIn() && !userSession.isSignInPending() && <Redirect to='/'/>}
                  {userSession.isUserSignedIn() && !userSession.isSignInPending() && userSession.loadUserData().username &&
                      <View userSession={userSession} />
          }
          </Provider>
         </Route>
    </Router>
    </>
  );
}

export default withRoot(Index);
