import React from 'react'
import style from './index.module.css'
import { Message } from '../Message'
import squiggly from './../../../images/prum.JPG';
import Tree from 'splaytree';

const emptyList = (
  <div className={style.empty}>
    <span role="img" aria-label="post">
      📝
    </span>
    <h2>No Messages Yet</h2>
  </div>
)

export const MessageList = ({ messages = {}, wtf, createConvo, pendingMessages = new Tree(), forPending = true, forNormal = false, user }) => (
    <div className={style.component} style={{
        padding: '0px', margin: '0px', borderWidth: '0px',
        backgroundColor: '#115F37', 
    }}>
    <ul id="messages" className={style.component} 
            style={{
                borderWidth: '0px', //backgroundImage: `url(${squiggly})`, borderWidth: '0px', //background:'rgba(255,255,255,0.1)',
            backgroundRepeat: 'no-repeat', //backgroundPosition: 'right top', backgroundSize:'30px 50px'
                backgroundSize: 'cover', //backgroundColor: 'rgba(100, 100, 100, 0.5)', overflow:'auto',//'rgba(0, 0, 0, 0.08)', //opacity: '.1'
            //background: 'linear - gradient(rgba(255, 255, 255, .5), rgba(255, 255, 255, .5)), `url(${squiggly})`'
        }}

        >
            <div style={{
                borderWidth: '0px',  //backgroundImage: `url(${squiggly})`, borderWidth: '0px', //background:'rgba(255,255,255,0.1)',
                backgroundRepeat: 'no-repeat', //backgroundPosition: 'right top', backgroundSize:'30px 50px'
                backgroundSize: 'cover', opacity: '.06', width: '100%', position: 'absolute', left: '0px', right: '0px', height: '100%' //https://scotch.io/tutorials/how-to-change-a-css-background-images-opacity
            }}>a</div>
            
        {(Object.keys(messages).length > 0 || Object.keys(pendingMessages.values()).length > 0) ? (
        <wrapper->
        {Object.keys(pendingMessages.values())
            .reverse()
            .map(k => Message({ user, createConvo, forPending })(pendingMessages.values()[k]))}
        {Object.keys(messages)
          .reverse()
            .map(k => Message({ user, createConvo, forNormal })(messages[k]))}
      </wrapper->
    ) : (
      emptyList
    )}
            
    
  </ul></div>
)

/*
 
 {(Object.keys(pendingMessages).length > 0 && pendingMessages.values()) ? (
        <wrapper->
                    {Object.keys(pendingMessages.values())
                .reverse()
                .map(k => Message({ user, createConvo })(pendingMessages.values()[k]))}
        </wrapper->
    ) : (
            emptyList
        )}
 
 */