import React from 'react'
import style from './index.module.css'

const placeholder =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

export const UserHeader = ({ user = {} }) => (
    <header className={style.component}
        style={{ backgroundColor:' #CFF2F9' }}
    >
    <img src={user.avatarURL || placeholder} alt={user.name} />
    <div>
            
      <h5>{user.id && `@${user.id}`}</h5>
    </div>
  </header>
)
//<h3>{user.name}</h3>