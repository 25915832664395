import React from 'react'
import style from './index.module.css'
import { FileInput } from '../FileInput'
import paperclip from './../../../images/paperclip.svg';
import rightArrow from './../../../images/right-arrow.svg';
//import gorilla from './../../../images/gorilla.svg';

export const CreateMessageForm = ({
  state: { user = {}, room = {}, message = '' },
  actions: { runCommand }, //todo: implement runcommand. i guess the invite system will be text based for now?
}) =>
  room.id ? (
        <form
            style={{
                backgroundColor: '#6c452d',
                boxShadow: '1px 0px 0px 1px black',
                borderTop: '1px solid #362317',
                borderRight: '1px solid #482e1e',
                zIndex:'5'
            }}
      className={style.component}
      onSubmit={e => {
        e.preventDefault()

        const message = e.target[0].value.trim()

        if (message.length === 0) {
          return
        }

        e.target[0].value = ''

        message.startsWith('/')
          ? runCommand(message.slice(1))
          : user.sendMessage(//{ // todo: how to bind a function to user like this...
              //text: message,
              //roomId: room.id,
            //}
                message
        )
      }}
    >
            <input
                style={{
                    backgroundColor: 'rgba(244, 244, 244, 1)',
                    marginTop: '1px',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    paddingLeft: '8px',
                    marginLeft: '8px',
                    borderRadius: '10px 10px 10px 10px',
                }}

        placeholder="Type a Message.." //vv doesnt seem like it works properly? see isTyping in View.js
        //onInput={console.log("todo message typing info")}//e => user.isTypingIn({ roomId: room.id })} // todo: assign so other users can see this user typing in chat
            />
            
            <button style={{ outline: 'none', backgroundColor:'Transparent'}}>
                <img src={rightArrow} style={{ maxWidth: '30px', height: 'auto', padding: '0px', marginRight: '2px', cursor:'pointer' }}>
                </img>
            </button>

    </form>
  ) : null
/*<FileInput state={{ user, room, message }} />
            <button type="submit" style={{ padding: '0px', margin: '0px' }}>
      </button>*/