import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import backgroundImage from './../../images/test.svg'; //insert cool background here

//const backgroundImage =
	//'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400&q=80';
	//'../images/cover.png';

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'left',
      height: '200px',
      maxWidth: '1200px',
      //minHeight: '200px',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto'
    //width: '100%'
  },
  button: {
	  minWidth: 200,
	  marginLeft: '16.5vw', // TODO: CONSISTENCY SO IT ISNT A HARD-CODED NUMBER
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
		  {/* Increase the network loading priority of the background image. */}
     
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);

/*<Button
		color="secondary"
	    backgroundColor="#80b04f"
        variant="contained"
        size="large"
        className={classes.button}
        component="a"
        href="/premium-themes/onepirate/sign-up/"
      >
        Join a chat!
      </Button>*/